import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { getToken } from "../user-service/user.service";
import {
  DOWNLOAD_FILE,
  DOWNLOAD_FILE_FOR_ADMIN,
  DOWNLOAD_USER_FILES,
  DOWNLOAD_USER_FILES_WITHOUT_CLOSING,
  GET_USER_FILES,
  GET_USER_FILES_BY_USERNAME,
  GET_USER_FOLDER,
  REMOVE_FILE,
  UPLOAD_FILE,
} from "./qraphql.schema";

export type File = {
  id: string;
  name: string;
  folder: string;
};

export type FileUpload = {
  name: string;
  type: string;
  size: string;
  lastModifiedDate: string;
};

export function GetUserFolder(folder: string) {
  const token = getToken();
  return useLazyQuery(GET_USER_FOLDER, {
    variables: { token, folder },
    fetchPolicy: "network-only",
  });
}

export function GetUserFiles(): File[] {
  const token = getToken();
  const { data } = useQuery(GET_USER_FILES, {
    variables: { token },
    fetchPolicy: "network-only",
  });
  return data?.getUserFiles;
}
export function GetUserFilesByUsername(username: string) {
  const token = getToken();
  return useQuery(GET_USER_FILES_BY_USERNAME, {
    variables: { token, username },
    fetchPolicy: "network-only",
  });
}

export function UploadFile(folder: string) {
  const token = getToken();
  return useMutation(UPLOAD_FILE, {
    refetchQueries: [
      { query: GET_USER_FILES, variables: { token: token } },
      { query: GET_USER_FOLDER, variables: { token: token, folder: folder } },
    ],
  });
}

export function base64ToArrayBuffer(base64: string) {
  const binaryString = window.atob(base64);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
}

export function DownloadUserFiles() {
  return useMutation(DOWNLOAD_USER_FILES);
}

export function DownloadUserFilesWithoutClosing() {
  return useMutation(DOWNLOAD_USER_FILES_WITHOUT_CLOSING);
}

export function DownloadFile() {
  return useMutation(DOWNLOAD_FILE);
}

export function DownloadFileForAdmin() {
  return useMutation(DOWNLOAD_FILE_FOR_ADMIN);
}

export function RemoveFile(folder: string) {
  const token = getToken();
  return useMutation(REMOVE_FILE, {
    refetchQueries: [
      { query: GET_USER_FOLDER, variables: { token: token, folder: folder } },
    ],
  });
}
