import { useState, ChangeEvent } from "react";
import { UploadFile } from "../../services/file-service/file.service";
import { getToken, LogoutUser } from "../../services/user-service/user.service";
import style from "./upload-file-modal.module.scss";
import { excelFormats } from "./upload.formats";
import { Maybe } from "../../utils/utils";

type Props = {
  title: string;
  folder: string;
  isVisible: boolean;
  closeModal: any;
  setMessage: any;
  excelOnly?: boolean;
};
const UploadFileModalComponent = (props: Props): React.ReactElement => {
  const { excelOnly, setMessage } = props;
  const [selectedFile, setSelectedFile] = useState<Maybe<FileList>>(null);
  const [uploadDenied, setUploadDenied] = useState(true);
  const [userInteractionEnabled, setUserInteractionEnabled] = useState(true);
  const onClose = () => {
    props.closeModal(false);
  };

  const isValidExtension = (files: FileList) => {
    if (!excelOnly) {
      return true;
    }
    for (let i = 0; i < files.length; i++) {
      const file: Maybe<File> = files.item(i);
      if (!file) {
        console.error("Something went wrong at file selection.");
        return false;
      }
      const isValid: boolean = excelFormats.some(
        (format) => format === file.type
      );
      if (!isValid) {
        return false;
      }
    }
    return true;
  };

  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files: Maybe<FileList> = event.target.files;

    if (!files) {
      return;
    }

    if (!isValidExtension(files)) {
      setMessage("Csak 'excel' táblázatok feltöltése engedélyezett a mappába.");
      onClose();
      return;
    }

    setUploadDenied(false);
    setSelectedFile(files);
  };

  const [uploadFile] = UploadFile(props.folder);
  const token = getToken();

  const inputRenderHelper = (): React.ReactElement => {
    if (props.excelOnly) {
      return (
        <input
          className="border border-black rounded text-sm px-2 py-1"
          type={"file"}
          onChange={onFileChange}
          accept={excelFormats.join(",")}
          required
          multiple
        />
      );
    }

    return (
      <input
        className="border border-black rounded text-sm px-2 py-1"
        type={"file"}
        onChange={onFileChange}
        required
        multiple
      />
    );
  };

  if (!props.isVisible) {
    return <></>;
  }
  return (
    <div
      className={` ${
        !props.isVisible ? "hidden" : ""
      } absolute text-color--green left-0 top-0 flex flex-col items-center overflow-hidden modalBackdrop`}
    >
      <div
        className={`${
          !props.isVisible ? "hidden" : ""
        } absolute border border-black rounded bg-white z-10 flex flex-col items-center px-8 py-8 top-20`}
      >
        <h1 className="font-bold mb-2">Fájl feltöltése</h1>
        <span className="text-sm mb-8">{props.title}</span>
        <form
          className="flex flex-col items-center"
          onSubmit={(event) => {
            props.setMessage("Feltöltés folyamatban. Kérem várjon!");
            setUploadDenied(true);
            setUserInteractionEnabled(false);

            event.preventDefault();
            event.stopPropagation();
            uploadFile({
              variables: {
                files: selectedFile,
                token: token,
                folder: props.folder,
              },
            })
              .then((res) => {
                setUploadDenied(false);
                props.setMessage(res.data.upload.message);
                setUserInteractionEnabled(true);
                props.closeModal(true);
              })
              .catch((errors) => {
                setUserInteractionEnabled(true);
                console.error(errors);
                let message = errors;
                if (
                  errors.message === "ThrottlerException: Too Many Requests"
                ) {
                  props.setMessage(
                    "Túl sok kérést hajtott végre! Kérem várjon egy percet, majd próbálja újra!"
                  );
                  return;
                }
                if (errors.graphQLErrors) {
                  if (
                    errors.graphQLErrors[0]?.extensions.response.statusCode ===
                    403
                  ) {
                    message = "Ismeretlen felhasználó! Kijelentkeztetjük!";
                    setTimeout(() => {
                      LogoutUser();
                    }, 1600);
                  }
                }
                props.setMessage(message);
              });
          }}
        >
          <div className="input-wrapper mb-4 flex flex-col">
            <label className="text-sm font-bold" htmlFor="username">
              Fájlnév:
            </label>

            {inputRenderHelper()}
          </div>
          <div className="w-full flex justify-evenly items-center">
            <button
              onClick={onClose}
              className="border-2 font-bold px-4 py-1 mt-8 rounded button--danger"
            >
              Mégse
            </button>
            <button
              className={`${
                uploadDenied ? "disabled" : ""
              } border-2 font-bold px-4 py-1 mt-8 rounded button--regular`}
              type="submit"
              disabled={uploadDenied}
            >
              Feltöltés
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default UploadFileModalComponent;
