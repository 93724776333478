import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  LogoutUser,
  resetPassword,
} from "../../services/user-service/user.service";

type Props = {
  setMessage: any;
};

const ResetPasswordPage = (props: Props): React.ReactElement => {
  const { secret } = useParams();
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const [resetPass] = resetPassword();

  return (
    <div className="text-color--green h-full min-h-full flex flex-col m-auto justify-center items-center gap-y-4 mt-8 mb-16">
      <div className="border-2 border--green px-4 py-4 rounded">
        <h1 className="text-2xl font-bold mb-8">Jeszó módosítása</h1>
        <form
          className="flex flex-col items-center"
          onSubmit={(e) => {
            e.preventDefault();
            if (password !== passwordAgain) {
              props.setMessage("A jelszavak eltérőek!");
              return;
            }
            resetPass({
              variables: {
                secret: secret,
                password: password,
              },
            })
              .then((res) => {
                props.setMessage(res.data.resetPassword.message);
              })
              .catch((e) => {
                if (e.message === "ThrottlerException: Too Many Requests") {
                  props.setMessage(
                    "Túl sok kérést hajtott végre! Kérem várjon egy percet, majd próbálja újra!"
                  );
                  return;
                }
                if (e.graphQLErrors) {
                  if (
                    e.graphQLErrors[0]?.extensions.response.statusCode === 403
                  ) {
                    props.setMessage(
                      "Ismeretlen felhasználó! Kijelentkeztetjük!"
                    );
                    setTimeout(() => {
                      LogoutUser();
                    }, 2500);
                  }
                }
              });
          }}
        >
          <div className="input-wrapper mb-4 flex flex-col">
            <label className="text-sm font-bold mb-2" htmlFor="name">
              Új jelszó:
            </label>
            <input
              className="border-2 border--green rounded text-sm px-2 py-1"
              type="password"
              id="name"
              onChange={(event) => {
                setPassword(event.target.value);
              }}
              required
            />
          </div>
          <div className="input-wrapper mb-4 flex flex-col">
            <label className="text-sm font-bold mb-2" htmlFor="username">
              Új jelszó újra:
            </label>
            <input
              className="border-2 border--green rounded text-sm px-2 py-1"
              type="password"
              onChange={(event) => {
                setPasswordAgain(event.target.value);
              }}
              required
            />
          </div>

          <button
            className="border-2 font-bold px-4 py-1 rounded button--regular"
            type="submit"
          >
            Módosítás
          </button>
        </form>
      </div>
    </div>
  );
};
export default ResetPasswordPage;
