import { useEffect, useState } from "react";
import { getUserExpirationDate } from "../../services/user-service/user.service";
import { getRemainTimeForUploading } from "../../utils/utils";

const remainInFormal = (remain: any) => {
  return `${remain.remainingDays} nap ${remain.remainingHours}:${remain.remainingMinutes}:${remain.remainingSeconds}`;
};

const RemainingTimeComponent = (): JSX.Element => {
  const checkIsHomePage = () => {
    if (window.location.pathname !== "/") {
      return false;
    }
    return true;
  };

  const [remainingTime, setRemainingTime] = useState({
    remainingDays: 0,
    remainingHours: 0,
    remainingMinutes: 0,
    remainingSeconds: 0,
  });
  const [isHomePage, setIsHomePage] = useState(checkIsHomePage());
  const { data, loading } = getUserExpirationDate();
  const expirationDate = data ? data.getUserExpirationDate : "";

  useEffect(() => {
    setIsHomePage(checkIsHomePage());
  }, [window.location.pathname]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (data) {
        setRemainingTime(getRemainTimeForUploading(expirationDate));
      }
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <div className="h-full min-h-full flex gap-4 flex-wrap m-auto justify-center items-center mt-4 font-bold px-4">
      <h1
        className={`text-sm text-center sm:text-left text-color--green ${
          isHomePage ? "text-lg" : ""
        } `}
      >
        A támogatás elszámolásáig hátralévő idő:
      </h1>
      <h1 className={`text-sm  text-color--red ${isHomePage ? "text-lg" : ""}`}>
        {remainInFormal(remainingTime)}
      </h1>
    </div>
  );
};
export default RemainingTimeComponent;
