import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UserRole } from "../../services/user-service/user-role.enum";
import {
  getToken,
  LogoutUser,
  ValidateUserRole,
} from "../../services/user-service/user.service";
import { navLinks } from "./nav-links";
import RemainingTime from "../remaining-time/remaining-time.component";
import style from "./header.module.scss";
import { ReactComponent as Logo } from "../../assets/images/szpma-logo.svg";

type Props = {
  links: any;
  isMobileMenuVisible: boolean;
  closeMobileMenu: any;
};

const MobileMenuComponent = (props: Props): JSX.Element => {
  const [userRole, setUserRole] = useState(UserRole.GUEST);
  const { data } = ValidateUserRole();

  useEffect(() => {
    if (data) {
      setUserRole(data.validateToken.userRole);
    }
  }, [data]);

  const handleCloseMobileMenu = () => {
    props.closeMobileMenu();
  };

  return (
    <div
      className={`${
        props.isMobileMenuVisible ? "flex" : "hidden"
      } lg:hidden absolute justify-center left-0 top-0 modalBackdrop`}
    >
      <div className="flex flex-col relative text-color--green bg-white text-center w-90vw h-fit mt-8 py-8">
        <button
          type="button"
          className="absolute top-2 right-4 font-bold text-color--red border-2 button--danger rounded px-2 py-0"
          onClick={handleCloseMobileMenu}
        >
          x
        </button>
        <div className="mt-8">
          {props.links
            .filter((link: any) => {
              return link.requiredRole.includes(userRole);
            })
            .map((menu: any) => (
              <div key={`${menu.path}`} className="my-2">
                <Link
                  className={`capitalize font-bold`}
                  id={`${menu.name}`}
                  to={`${menu.path}`}
                  onClick={() => {
                    handleCloseMobileMenu();
                  }}
                >
                  {menu.name.toUpperCase()}
                </Link>
                <hr className="mt-4" />
              </div>
            ))}
          {userRole !== UserRole.GUEST ? (
            <button className="mt-2" onClick={LogoutUser}>
              {"Kilépés".toUpperCase()}
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default MobileMenuComponent;
