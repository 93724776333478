import { useState } from "react";
import { MessageType } from "../../services/message-type.enum";
import { UserStatus } from "../../services/user-service/user-status.enum";
import {
  banOrActivateUser,
  getToken,
  LogoutUser,
  User,
} from "../../services/user-service/user.service";
import MessageBarComponent from "../message-bar/message-bar.component";
import ModalComponent from "../modal/modal.component";

type Props = {
  user: User;
  setMessage: any;
};

const ChangeUserStatusButton = (props: Props): JSX.Element => {
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [changeUserStatus] = banOrActivateUser();

  const hideModal = () => {
    setIsVisibleModal(false);
  };

  const isUserStatusActive = () => {
    return props.user.status === UserStatus.ACTIVE;
  };

  const callbackForChangeUserStatus = (userId: string) => {
    const token = getToken();
    changeUserStatus({
      variables: { token, userId: userId },
    })
      .then((response) => {
        const res = response.data.banOrActivateUser;
        props.setMessage(res.message);
      })
      .catch((errors) => {
        if (errors.message === "ThrottlerException: Too Many Requests") {
          props.setMessage(
            "Túl sok kérést hajtott végre! Kérem várjon egy percet, majd próbálja újra!"
          );
          return;
        }
        let message = errors;
        if (errors.graphQLErrors) {
          if (errors.graphQLErrors[0]?.extensions.response.statusCode === 403) {
            message = "Ismeretlen felhasználó! Kijelentkeztetjük!";
            setTimeout(() => {
              LogoutUser();
            }, 2500);
          }
        }
        props.setMessage(message);
      });
  };
  return (
    <>
      <ModalComponent
        title={`Felhasználó belépésének ${
          isUserStatusActive() ? "tiltása" : "engedélyezése"
        }`}
        message={`Biztosan ${
          isUserStatusActive() ? "tiltani" : "engedélyezni"
        } szeretné a "${
          props.user.username
        }" nevezetű felhasználó bejelentlezését?`}
        submitButtonName={`${isUserStatusActive() ? "Tiltás" : "Engedélyezés"}`}
        callBack={callbackForChangeUserStatus}
        callBackParams={props.user.id}
        isVisible={isVisibleModal}
        closeModal={hideModal}
      />
      <div className="w-3/12">
        <button
          onClick={() => {
            setIsVisibleModal(true);
          }}
          className="border-2 font-bold px-2 py-0 rounded button--warning"
        >
          {isUserStatusActive() ? "Tiltás" : "Engedélyezés"}
        </button>
      </div>
    </>
  );
};

export default ChangeUserStatusButton;
