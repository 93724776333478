import { useState } from "react";
import {
  deleteUser,
  getToken,
  LogoutUser,
  User,
} from "../../services/user-service/user.service";
import ModalComponent from "../modal/modal.component";

type Props = {
  user: User;
  setMessage: any;
};

const DeleteButton = (props: Props): JSX.Element => {
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [deleteUserWithId] = deleteUser();

  const hideModal = () => {
    setIsVisibleModal(false);
  };

  const callbackForUserDelete = (userId: string) => {
    const token = getToken();
    deleteUserWithId({
      variables: { token, userId: userId },
    })
      .then((response) => {
        const res = response.data.deleteUser;
        props.setMessage(res.message);
      })
      .catch((errors) => {
        if (errors.message === "ThrottlerException: Too Many Requests") {
          props.setMessage(
            "Túl sok kérést hajtott végre! Kérem várjon egy percet, majd próbálja újra!"
          );
          return;
        }
        let message = errors;
        if (errors.graphQLErrors[0]?.extensions.response.statusCode === 403) {
          message = "Ismeretlen felhasználó! Kijelentkeztetjük!";
          setTimeout(() => {
            LogoutUser();
          }, 1600);
        }
        props.setMessage(message);
      });
  };
  return (
    <div className="">
      <ModalComponent
        title={"Felhasználó törlése"}
        message={`Biztosan törölni szeretné a "${props.user.username}" nevezetű felhasználót?`}
        submitButtonName={"Törlés"}
        callBack={callbackForUserDelete}
        callBackParams={props.user.id}
        isVisible={isVisibleModal}
        closeModal={hideModal}
      />
      <div className="w-2/12">
        <button
          onClick={() => {
            setIsVisibleModal(true);
          }}
          className="border-2 font-bold px-2 py-0 rounded button--danger"
        >
          Törlés
        </button>
      </div>
    </div>
  );
};

export default DeleteButton;
