import React from "react";
import UserFilesTableComponent from "../../components/file-list-table/user-files-table.component";
import {
  LogoutUser,
  ValidateUserRole,
} from "../../services/user-service/user.service";
import { useEffect } from "react";
import { UserRole } from "../../services/user-service/user-role.enum";

type Props = {
  setMessage: any;
};

const UserFilesPage = (props: Props): React.ReactElement => {
  const { data } = ValidateUserRole();
  useEffect(() => {
    if (data) {
      if (data.validateToken.userRole !== UserRole.USER) {
        LogoutUser();
      }
    }
  }, [data]);
  if (!data) {
    return <></>;
  }

  return <UserFilesTableComponent setMessage={props.setMessage} />;
};
export default UserFilesPage;
