import { useEffect } from "react";
import CreateUserFormComponent from "../../components/create-user-form/create-user-form.component";
import { UserRole } from "../../services/user-service/user-role.enum";
import {
  LogoutUser,
  ValidateUserRole,
} from "../../services/user-service/user.service";

type Props = {
  setMessage: any;
};

const CreateUserPage = (props: Props): JSX.Element => {
  const { data } = ValidateUserRole();
  useEffect(() => {
    if (data) {
      if (data.validateToken.userRole !== UserRole.ADMIN) {
        LogoutUser();
      }
    }
  }, [data]);
  if (!data) {
    return <></>;
  }
  return <CreateUserFormComponent setMessage={props.setMessage} />;
};
export default CreateUserPage;
