export function getDateNowForDateTimeInput(): string {
  const timeElapsed = Date.now();
  const today = new Date(timeElapsed);
  const year = today.getFullYear();
  const month =
    today.getMonth() + 1 > 9
      ? today.getMonth() + 1
      : "0" + (today.getMonth() + 1);
  const day = today.getDate() > 9 ? today.getDate() : "0" + today.getDate();
  return `${year}-${month}-${day}T23:59`;
}

export function getRemainTimeForUploading(date: Date) {
  const expirationDate: any = new Date(date);
  const now = Date.now();

  const totalSeconds = Math.floor((expirationDate - now) / 1000);
  const totalMinutes = Math.floor(totalSeconds / 60);
  const totalHours = Math.floor(totalMinutes / 60);
  const totalDays = Math.floor(totalHours / 24);

  const hours = totalHours - totalDays * 24;
  const minutes = totalMinutes - totalDays * 24 * 60 - hours * 60;
  const seconds =
    totalSeconds - totalDays * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;

  return {
    remainingDays: totalDays,
    remainingHours: hours,
    remainingMinutes: minutes,
    remainingSeconds: seconds,
  };
}

export type Maybe<T> = T | null | undefined;
