import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import MailOutlineRoundedIcon from "@mui/icons-material/MailOutlineRounded";

const FooterComponent = (): JSX.Element => {
  return (
    <div className="w-full h-12  flex fixed bottom-0 background--green justify-center ">
      <div className={`flex items-center gap-4`}>
        <div className="">
          <span className="font-bold">Elérhetőségeink:</span>
        </div>
        <div className="flex gap-2">
          <a
            href="https://www.facebook.com/polgarima"
            className="text-white rounded font-bold text-xl"
            target="_blank"
            rel="noreferrer"
          >
            <FacebookRoundedIcon />
          </a>
        </div>
        <div className="flex gap-2">
          <a
            href="https://szpma.hu/"
            className="border-2 button--footer rounded font-bold px-2"
            target="_blank"
            rel="noreferrer"
          >
            Honlapunk
          </a>
        </div>
        <div className="flex gap-2">
          <a
            href="mailto:igazgatosag@szpma.hu"
            className="text-white font-bold text-2xl pt-1"
            target="_blank"
            rel="noreferrer"
          >
            <MailOutlineRoundedIcon />
          </a>
        </div>
      </div>
    </div>
  );
};
export default FooterComponent;
