import React, { useEffect, useState } from "react";
import { MessageType } from "../../services/message-type.enum";
import {
  CreateUser,
  getToken,
  LogoutUser,
} from "../../services/user-service/user.service";
import { getDateNowForDateTimeInput } from "../../utils/utils";
import MessageBarComponent from "../message-bar/message-bar.component";

type Props = {
  setMessage: any;
};

const CreateUserFormComponent = (props: Props): React.ReactElement => {
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [type, setType] = useState(MessageType.SUCCESS);
  const [selectedExpirationDate, setSelectedExpirationDate] = useState(
    getDateNowForDateTimeInput
  );
  const [createUser] = CreateUser();
  const token = getToken();

  const hideMessageBar = () => {
    setTimeout(() => {
      setIsVisible(false);
    }, 5000);
  };

  useEffect(() => {
    if (isVisible) {
      hideMessageBar();
    }
  }, [isVisible]);

  const onExpirationDateChange = (event: any) => {
    setSelectedExpirationDate(event.currentTarget.value);
  };

  return (
    <div
      className={`xl:w-60 border-2 border--green flex flex-col items-center rounded mb-12 p-8 text-color--green`}
    >
      <h1 className="text-2xl mb-4 w-full">
        <b className="block w-full text-left">Felhasználó</b>
        <b className="block w-full text-right"> létrehozása</b>
      </h1>
      <form
        className="flex flex-col items-center"
        onSubmit={(e) => {
          e.preventDefault();
          createUser({
            variables: {
              name: name,
              password: password,
              email: email,
              username: username,
              token: token,
              expirationDate: selectedExpirationDate,
            },
          })
            .then((res) => {
              props.setMessage(res.data.createUser.message);
            })
            .catch((e) => {
              if (e.message === "ThrottlerException: Too Many Requests") {
                props.setMessage(
                  "Túl sok kérést hajtott végre! Kérem várjon egy percet, majd próbálja újra!"
                );
                return;
              }
              if (e.graphQLErrors) {
                if (
                  e.graphQLErrors[0]?.extensions?.response?.statusCode === 403
                ) {
                  props.setMessage(
                    "Ismeretlen felhasználó! Kijelentkeztetjük!"
                  );
                  setTimeout(() => {
                    LogoutUser();
                  }, 2500);
                }
              }
            });
        }}
      >
        <div className="input-wrapper mb-4 flex flex-col">
          <label className="text-sm" htmlFor="name">
            Név:
          </label>
          <input
            className="border border--green rounded text-sm px-2 py-1"
            type="text"
            id="name"
            onChange={(event) => {
              setName(event.target.value);
            }}
            required
          />
        </div>
        <div className="input-wrapper mb-4 flex flex-col">
          <label className="text-sm" htmlFor="username">
            Felhasználónév:
          </label>
          <input
            className="border border--green rounded text-sm px-2 py-1"
            type="text"
            onChange={(event) => {
              setUsername(event.target.value);
            }}
            required
          />
        </div>
        <div className="input-wrapper mb-4 flex flex-col">
          <label className="text-sm" htmlFor="email">
            E-mail:
          </label>
          <input
            className="border border--green rounded text-sm px-2 py-1"
            type="email"
            onChange={(event) => {
              setEmail(event.target.value);
            }}
            required
          />
        </div>
        <div className="input-wrapper mb-4 flex flex-col">
          <label className="text-sm" htmlFor="username">
            Jelszó:
          </label>
          <input
            className="border border--green rounded text-sm px-2 py-1"
            type="password"
            onChange={(event) => {
              setPassword(event.target.value);
            }}
            required
          />
        </div>
        <div className="input-wrapper w-full mb-4 flex flex-col">
          <label className="text-sm" htmlFor="expirationDate">
            Határidő:
          </label>
          <input
            id="expirationDate"
            className="border border--green rounded text-sm px-2 py-1"
            type="datetime-local"
            defaultValue={selectedExpirationDate}
            onChange={onExpirationDateChange}
          />
        </div>
        <button
          className="border px-4 py-1 rounded button--regular"
          type="submit"
        >
          Létrehozás
        </button>
      </form>
    </div>
  );
};
export default CreateUserFormComponent;
