import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  forgotPassword,
  LogoutUser,
} from "../../services/user-service/user.service";

type Props = {
  setMessage: any;
};

const ForgotPasswordPage = (props: Props): JSX.Element => {
  const [email, setEmail] = useState("");
  const [forgotPass, { data }] = forgotPassword();
  const navigate = useNavigate();

  return (
    <div className="text-color--green h-full min-h-full flex flex-col m-auto justify-center items-center gap-y-4 mt-8 mb-16">
      <div className="border-2 border--green px-4 py-4 rounded w-11/12">
        <h1 className="text-2xl font-bold text-center">
          Jeszó módosítás kérése
        </h1>
        <h2 className="text-xs mt-2 text-center">
          A módosítási folyamat megkezdéséhez kérem adja meg E-mail címét!
        </h2>
        <form
          className="flex flex-col items-center mt-8"
          onSubmit={(e) => {
            e.preventDefault();
            forgotPass({
              variables: {
                email: email,
              },
            })
              .then((res) => {
                props.setMessage(res.data.forgotPassword.message);
              })
              .catch((e) => {
                if (e.message === "ThrottlerException: Too Many Requests") {
                  props.setMessage(
                    "Túl sok kérést hajtott végre! Kérem várjon egy percet, majd próbálja újra!"
                  );
                  return;
                }
                if (e.graphQLErrors) {
                  if (
                    e.graphQLErrors[0]?.extensions.response.statusCode === 403
                  ) {
                    props.setMessage(
                      "Ismeretlen felhasználó! Kijelentkeztetjük!"
                    );
                    setTimeout(() => {
                      LogoutUser();
                    }, 2500);
                  }
                }
              });
          }}
        >
          <div className="input-wrapper mb-4 flex flex-col">
            <label className="text-sm font-bold mb-2" htmlFor="username">
              E-mail:
            </label>
            <input
              className="border-2 border--green rounded text-sm px-2 py-1"
              type="email"
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              required
            />
          </div>
          <div className="w-full flex justify-evenly">
            <button
              className="border-2 font-bold px-4 py-1 rounded button--warning"
              type="button"
              onClick={() => navigate("/login")}
            >
              Vissza
            </button>
            <button
              className="border-2 font-bold px-4 py-1 rounded button--regular"
              type="submit"
            >
              Küldés
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default ForgotPasswordPage;
