import { useEffect, useState } from 'react'
import {
  base64ToArrayBuffer,
  DownloadFileForAdmin,
  DownloadUserFilesWithoutClosing,
  GetUserFilesByUsername,
} from '../../services/file-service/file.service'
import { File } from '../../services/file-service/file.service'
import { MessageType } from '../../services/message-type.enum'
import style from './list-users-table.module.scss'
import { getToken, LogoutUser, User } from '../../services/user-service/user.service'

type Props = {
  isVisible: boolean
  user: any
  closeModal: any
  setMessage: any
}

const UserDataModalComponent = (props: Props): JSX.Element => {
  const close = () => {
    props.closeModal()
  }

  return (
    <div
      className={`${
        !props.isVisible ? 'hidden' : ''
      } fixed left-0 top-0 overflow-hidden flex justify-center items-center modalBackdrop`}
    >
      <div
        className={`${
          !props.isVisible ? 'hidden' : ''
        } w-full sm:w-min absolute border border-black rounded bg-white z-10 flex flex-col items-center justify-between px-4 py-4  2xl:min-h-50vh 2xl:max-h-50vh`}
      >
        <div className="w-full flex justify-between items-center gap-2 border-b-2 border-black mb-6  w-90vw lg:w-50vw">
          <h1 className="font-bold pb-2">{props.user.name} adatai</h1>
        </div>
        <div className="w-full px-4 flex flex-col gap-4">
          <div className="w-12/12 font-bold flex gap-4">
            <span>Név:</span>
            <span>{props.user.name}</span>
          </div>
          <div className="w-12/12 font-bold flex gap-4">
            <span>Felhasználónév:</span>
            <span>{props.user.username}</span>
          </div>
          <div className="w-12/12 font-bold flex gap-4">
            <span>E-mail:</span>
            <span>{props.user.email}</span>
          </div>
        </div>

        <button
          className="border-2 button--danger font-bold rounded px-2 py-1 mt-4 2xl:mt-0"
          type="button"
          onClick={close}
        >
          Bezárás
        </button>
      </div>
    </div>
  )
}
export default UserDataModalComponent
