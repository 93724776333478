import { gql } from "@apollo/client";

export const GET_USER_FOLDER = gql`
  query GetUserFolder($token: String!, $folder: String!) {
    getUserFolder(token: $token, folder: $folder) {
      id
      name
      folder
    }
  }
`;

export const GET_USER_FILES = gql`
  query GetUserFiles($token: String!) {
    getUserFiles(token: $token) {
      id
      name
      folder
    }
  }
`;

export const GET_USER_FILES_BY_USERNAME = gql`
  query GetUserFilesByUsername($token: String!, $username: String!) {
    getUserFilesByUsername(token: $token, username: $username) {
      id
      name
      folder
    }
  }
`;

export const UPLOAD_FILE = gql`
  mutation Upload($files: [Upload!]!, $folder: String!, $token: String!) {
    upload(files: $files, folder: $folder, token: $token) {
      type
      message
    }
  }
`;

export const DOWNLOAD_USER_FILES = gql`
  mutation DownloadUserFiles($token: String!, $userId: String!) {
    downloadUserFiles(token: $token, userId: $userId) {
      type
      message
    }
  }
`;

export const DOWNLOAD_USER_FILES_WITHOUT_CLOSING = gql`
  mutation DownloadUserFilesWithoutClosing(
    $token: String!
    $username: String!
  ) {
    downloadUserFilesWithoutClosing(token: $token, username: $username) {
      type
      message
    }
  }
`;

export const DOWNLOAD_FILE = gql`
  mutation DownloadFile(
    $token: String!
    $folderName: String!
    $fileName: String!
  ) {
    downloadFile(token: $token, folderName: $folderName, fileName: $fileName) {
      type
      message
    }
  }
`;

export const DOWNLOAD_FILE_FOR_ADMIN = gql`
  mutation DownloadFileForAdmin(
    $token: String!
    $username: String!
    $folderName: String!
    $fileName: String!
  ) {
    downloadFileForAdmin(
      token: $token
      username: $username
      folderName: $folderName
      fileName: $fileName
    ) {
      type
      message
    }
  }
`;

export const REMOVE_FILE = gql`
  mutation RemoveFile($token: String!, $fileId: String!) {
    removeFile(token: $token, fileId: $fileId) {
      type
      message
    }
  }
`;
