export enum FolderNames {
  CONTENTREPORT = "tartalmi_beszamolo",
  INVOICESUMMARY = "szamlaosszesito",
  INVOICESUMMARY_SCANNED = "szamlaosszesito_scannelt_alairt",
  BILLSANDRECEIPTS = "szamlak-bizonylatok",
  CONTRACTS = "szerzodesek-megrendelok-teljesites_igazolasok",
  TRAVELRECORDS = "utnyilvantartas-kikuldetesi_rendelveny",
  PROOFSOFDEVELOPMENT = "megvalosulast_igazolo_dokumentaciok",
  OTHER = "egyeb",
}
