import { gql } from '@apollo/client'

export const CREATE_USER = gql`
  mutation CreateUser(
    $name: String!
    $password: String!
    $email: String!
    $username: String!
    $token: String!
    $expirationDate: DateTime!
  ) {
    createUser(
      name: $name
      password: $password
      role: "USER"
      email: $email
      username: $username
      token: $token
      expirationDate: $expirationDate
    ) {
      type
      message
      user {
        name
      }
    }
  }
`

export const LOGIN_USER = gql`
  mutation signIn($password: String!, $username: String!) {
    signIn(password: $password, username: $username) {
      type
      message
    }
  }
`

export const VALIDATE_TOKEN = gql`
  query ValidateToken($token: String!) {
    validateToken(token: $token) {
      userRole
    }
  }
`

export const GET_ALL_USER = gql`
  query GetAllUser($token: String!) {
    getAllUser(token: $token) {
      id
      username
      role
      status
      email
      name
    }
  }
`
export const DELETE_USER = gql`
  mutation DeleteUser($token: String!, $userId: String!) {
    deleteUser(token: $token, userId: $userId) {
      type
      message
    }
  }
`
export const BAN_OR_ACTIVATE_USER = gql`
  mutation BanOrActivateUser($token: String!, $userId: String!) {
    banOrActivateUser(token: $token, userId: $userId) {
      type
      message
    }
  }
`
export const CLOSE_USER = gql`
  mutation CloseUser($token: String!, $userId: String!) {
    closeUser(token: $token, userId: $userId) {
      type
      message
    }
  }
`

export const FINALIZE_ACCOUNT = gql`
  mutation FinalizeAccount($token: String!) {
    finalizeAccount(token: $token) {
      type
      message
    }
  }
`

export const VALIDATE_TWO_FACTOR_CODE = gql`
  mutation ValidateTwoFactorToken($username: String!, $twoFactorToken: String!) {
    validateTwoFactorToken(username: $username, twoFactorToken: $twoFactorToken) {
      type
      message
      accessToken
    }
  }
`
export const GET_USER_EXPIRATION_DATE = gql`
  query GetUserExpirationDate($token: String!) {
    getUserExpirationDate(token: $token)
  }
`

export const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(email: $email) {
      type
      message
    }
  }
`

export const RESET_PASSWORD = gql`
  mutation ResetPassword($secret: String!, $password: String!) {
    resetPassword(secret: $secret, password: $password) {
      type
      message
    }
  }
`
