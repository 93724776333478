import { useState } from "react";
import DownloadLinkComponent from "./download-link.component";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";

type Props = {
  title: string;
  documents: any;
};

const DownloadFolderComponent = (props: Props): JSX.Element => {
  const [isFolderOpened, setIsFolderOpened] = useState(true);

  const handleOpen = () => {
    setIsFolderOpened(!isFolderOpened);
  };
  return (
    <div className="w-full">
      <div
        className={`flex w-full w-90vw lg:w-77vw px-4 py-2 justify-between items-center cursor-pointer border-2 font-bold border-black button--regular rounded mb-2 ${
          isFolderOpened ? "background--green" : ""
        }`}
        onClick={() => handleOpen()}
      >
        <span>{props.title}</span>

        {isFolderOpened ? (
          <KeyboardArrowUpRoundedIcon />
        ) : (
          <KeyboardArrowDownRoundedIcon />
        )}
      </div>
      <div className={`${isFolderOpened ? "flex" : "hidden"} flex-col`}>
        {props.documents.map((document: any) => {
          return (
            <DownloadLinkComponent title={document.title} url={document.url} />
          );
        })}
      </div>
    </div>
  );
};
export default DownloadFolderComponent;
