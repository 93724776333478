import LoginFormComponent from "../../components/login-form/login-form.component";

type Props = {
  setMessage: any;
};

const LoginPage = (props: Props): JSX.Element => {
  return <LoginFormComponent setMessage={props.setMessage} />;
};
export default LoginPage;
