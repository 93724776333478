import { useState } from 'react'
import { FolderNames } from '../../services/file-service/folder-names.enum'
import ModalComponent from '../modal/modal.component'
import FolderComponent from './folder.component'
import { LogoutUser, finalizeAccount, getToken } from '../../services/user-service/user.service'

type Props = {
  setMessage: any
}

const UserFilesTableComponent = (props: Props): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [finalizeTheAccount] = finalizeAccount()
  const onCloseModal = () => {
    setIsModalOpen(false)
  }

  const onOpenModal = () => {
    setIsModalOpen(true)
  }

  const callbackForFinalizeAccount = () => {
    const token = getToken()
    finalizeTheAccount({
      variables: { token },
    })
      .then((response) => {
        const res = response.data.finalizeAccount
        props.setMessage(res.message)
        setTimeout(() => {
          LogoutUser()
        }, 3000)
      })
      .catch((errors) => {
        if (errors.message === 'ThrottlerException: Too Many Requests') {
          props.setMessage('Túl sok kérést hajtott végre! Kérem várjon egy percet, majd próbálja újra!')
          return
        }
        let message = errors
        if (errors.graphQLErrors) {
          if (errors.graphQLErrors[0]?.extensions.response.statusCode === 403) {
            message = 'Ismeretlen felhasználó! Kijelentkeztetjük!'
            setTimeout(() => {
              LogoutUser()
            }, 1600)
          }
        }
        props.setMessage(message)
      })
  }

  return (
    <div className="w-80vw mt-4 mb-12">
      <ModalComponent
        title={'Véglegesítés'}
        message={'Biztos benne, hogy véglegesíti a támogatás elszámolását?'}
        submitButtonName={'Véglegesít'}
        callBack={callbackForFinalizeAccount}
        callBackParams={undefined}
        isVisible={isModalOpen}
        closeModal={onCloseModal}
      />
      <button
        className="border-2 font-bold border-black px-4 py-2 rounded text-xs w-full my-4 button--warning cursor-pointer"
        type="button"
        onClick={onOpenModal}
      >
        Véglegesítés
      </button>
      <FolderComponent
        title={'Tartalmi beszámoló'}
        folderName={FolderNames.CONTENTREPORT}
        setMessage={props.setMessage}
      />
      <FolderComponent
        title={'Számlaösszesítő (szerkeszthető excel)'}
        folderName={FolderNames.INVOICESUMMARY}
        setMessage={props.setMessage}
        excelOnly={true}
      />
      <FolderComponent
        title={'Számlaösszesítő (scannelt, aláírt)'}
        folderName={FolderNames.INVOICESUMMARY_SCANNED}
        setMessage={props.setMessage}
      />
      <FolderComponent
        title={'Számlák, bizonylatok, kiadási pénztárbizonylatok, bankszámlakivonatok'}
        folderName={FolderNames.BILLSANDRECEIPTS}
        setMessage={props.setMessage}
      />
      <FolderComponent
        title={'Szerződések, megrendelők, teljesítés igazolások'}
        folderName={FolderNames.CONTRACTS}
        setMessage={props.setMessage}
      />
      <FolderComponent
        title={'Útnyilvántartás, kiküldetési rendelvény'}
        folderName={FolderNames.TRAVELRECORDS}
        setMessage={props.setMessage}
      />
      <FolderComponent
        title={'Megvalósulást igazoló dokumentációk (pl.: képek, plakátok, meghívók, jelenléti ívek stb.)'}
        folderName={FolderNames.PROOFSOFDEVELOPMENT}
        setMessage={props.setMessage}
      />
      <FolderComponent title={'Egyéb'} folderName={FolderNames.OTHER} setMessage={props.setMessage} />
    </div>
  )
}
export default UserFilesTableComponent
