import "./App.css";
import "./styles/global.scss";
import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/home/home.page";
import Layout from "./layout/layout";
import LoginPage from "./pages/login/login.page";
import HeaderComponent from "./components/header/header.component";
import CreateUserPage from "./pages/create-user/create-user.page";
import ListUsersPage from "./pages/list-users/list-users.page";
import UserFilesPage from "./pages/user-files/user-files.page";
import DownloadsPage from "./pages/downloads/downloads.page";
import React, { useEffect, useState } from "react";
import BackendResponseComponent from "./components/backend-response/backend-response.component";
import FooterComponent from "./components/footer/footer.component";
import ResetPasswordPage from "./pages/reset-password/reset-password.page";
import ForgotPasswordPage from "./pages/forgot-password/forgot-password.page";

function App(): React.ReactElement {
  const [message, setMessage] = useState("");
  useEffect(() => {
    const timer = setTimeout(() => {
      setMessage("");
    }, 3000);
    return () => clearTimeout(timer);
  }, [message]);

  return (
    <div>
      <HeaderComponent />
      <BackendResponseComponent message={message} />
      <Routes>
        <Route
          path="/"
          element={
            <Layout>
              <HomePage />
            </Layout>
          }
        />
        <Route
          path="/login"
          element={
            <Layout>
              <LoginPage setMessage={setMessage} />
            </Layout>
          }
        />
        <Route
          path="/create-user"
          element={
            <Layout>
              <CreateUserPage setMessage={setMessage} />
            </Layout>
          }
        />
        <Route
          path="/list-users"
          element={
            <Layout>
              <ListUsersPage setMessage={setMessage} />
            </Layout>
          }
        />
        <Route
          path="/user-files"
          element={
            <Layout>
              <UserFilesPage setMessage={setMessage} />
            </Layout>
          }
        />
        <Route
          path="/downloads"
          element={
            <Layout>
              <DownloadsPage />
            </Layout>
          }
        />
        <Route
          path="/reset-password/:secret"
          element={
            <Layout>
              <ResetPasswordPage setMessage={setMessage} />
            </Layout>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <Layout>
              <ForgotPasswordPage setMessage={setMessage} />
            </Layout>
          }
        />
      </Routes>
      <FooterComponent />
    </div>
  );
}

export default App;
