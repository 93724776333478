import { useState } from 'react'
import { closeUser, getToken, LogoutUser, User } from '../../services/user-service/user.service'
import ModalComponent from '../modal/modal.component'

type Props = {
  user: User
  setMessage: any
}

const CloseUserButton = (props: Props): JSX.Element => {
  const [isVisibleModal, setIsVisibleModal] = useState(false)
  const [closeTheUser] = closeUser()

  const hideModal = () => {
    setIsVisibleModal(false)
  }

  const callbackForCloseUSer = (userId: string) => {
    const token = getToken()
    closeTheUser({
      variables: { token, userId: userId },
    })
      .then((response) => {
        const res = response.data.closeUser
        props.setMessage(res.message)
      })
      .catch((errors) => {
        if (errors.message === 'ThrottlerException: Too Many Requests') {
          props.setMessage('Túl sok kérést hajtott végre! Kérem várjon egy percet, majd próbálja újra!')
          return
        }
        let message = errors
        if (errors.graphQLErrors) {
          if (errors.graphQLErrors[0]?.extensions.response.statusCode === 403) {
            message = 'Ismeretlen felhasználó! Kijelentkeztetjük!'
            setTimeout(() => {
              LogoutUser()
            }, 1600)
          }
        }
        props.setMessage(message)
      })
  }
  return (
    <div className="">
      <ModalComponent
        title={'Felhasználó zárolása'}
        message={`Biztosan zárolni szeretné a "${props.user.username}" nevezetű felhasználót?`}
        submitButtonName={'Zárolás'}
        callBack={callbackForCloseUSer}
        callBackParams={props.user.id}
        isVisible={isVisibleModal}
        closeModal={hideModal}
      />
      <div className="w-2/12">
        <button
          onClick={() => {
            setIsVisibleModal(true)
          }}
          className="border-2 font-bold px-2 py-0 rounded button--danger"
        >
          Zárolás
        </button>
      </div>
    </div>
  )
}

export default CloseUserButton
