import { useEffect } from "react";
import ListUsersTableComponent from "../../components/list-users-table/list-users-table.component";
import { UserRole } from "../../services/user-service/user-role.enum";
import {
  LogoutUser,
  ValidateUserRole,
} from "../../services/user-service/user.service";

type Props = {
  setMessage: any;
};

const ListUsersPage = (props: Props): JSX.Element => {
  const { data } = ValidateUserRole();
  useEffect(() => {
    if (data) {
      if (data.validateToken.userRole !== UserRole.ADMIN) {
        LogoutUser();
      }
    }
  }, [data]);
  if (!data) {
    return <></>;
  }
  return <ListUsersTableComponent setMessage={props.setMessage} />;
};
export default ListUsersPage;
