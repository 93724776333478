import { useEffect, useState } from "react";
import { MessageType } from "../../services/message-type.enum";
import {
  loginSuccess,
  validateTwoFactorToken,
} from "../../services/user-service/user.service";
import MessageBarComponent from "../message-bar/message-bar.component";
import style from "./two-factor-modal.module.scss";

type Props = {
  username: string;
  isVisible: boolean;
  closeModal: any;
  setMessage: any;
};
const TwoFactorModalComponent = (props: Props): JSX.Element => {
  const [code, setCode] = useState("code");
  const [validateTwoFactor] = validateTwoFactorToken();

  const handleCancel = () => {
    props.closeModal();
  };
  const handleSubmit = () => {
    validateTwoFactor({
      variables: { username: props.username, twoFactorToken: code },
    })
      .then((res) => {
        props.setMessage(res.data.validateTwoFactorToken.message);

        if (res.data.validateTwoFactorToken.type === MessageType.SUCCESS) {
          loginSuccess(res.data.validateTwoFactorToken.accessToken);
        }
      })
      .catch((e) => {
        if (e.message === "ThrottlerException: Too Many Requests") {
          props.setMessage(
            "Túl sok kérést hajtott végre! Kérem várjon egy percet, majd próbálja újra!"
          );
          return;
        }
        props.setMessage(e.message);
      });
  };

  if (!props.isVisible) {
    return <></>;
  }
  return (
    <div
      className={` ${
        !props.isVisible ? "hidden" : ""
      } absolute text-color--green left-0 top-0 flex flex-col items-center overflow-hidden ${
        style.modalBackdrop
      }`}
    >
      <div
        className={`${
          !props.isVisible ? "hidden" : ""
        } absolute border border-black rounded bg-white z-10 flex flex-col items-center px-4 py-4 top-40`}
      >
        <h1 className="font-bold pb-2">Kétlépcsős azonosítás</h1>
        <h3 className="text-sm mb-4">Kérem adja meg e-mailben kapott kódját</h3>
        <input
          type="text"
          className="border border-black rounded mb-4 px-2 py-2 text-sm"
          placeholder="Azonosító..."
          onChange={(node) => {
            setCode(node.target.value);
          }}
        />
        <div className="flex w-3/4 justify-between">
          <button
            className="border-2 font-bold button--danger rounded px-2 py-1"
            onClick={handleCancel}
          >
            Mégse
          </button>
          <button
            className="border-2 font-bold button--regular rounded px-2 py-1"
            onClick={() => handleSubmit()}
          >
            Belépés
          </button>
        </div>
      </div>
    </div>
  );
};
export default TwoFactorModalComponent;
