import React from "react";
import styles from "./layout.module.scss";

const Layout = (prop: {
  children:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
}): JSX.Element => {
  return (
    <div
      className={`flex flex-col justify-center items-center ${styles.layoutHeight}`}
    >
      {prop.children}
    </div>
  );
};

export default Layout;
