type Props = {
  title: string;
  url: string;
};

const DownloadLinkComponent = (props: Props): JSX.Element => {
  return (
    <div className="mt-4 sm:0 flex flex-col sm:flex-row items-center w-90vw lg:w-77vw justify-center sm:justify-between">
      <span className="mb-4 sm:mb-0 text-center sm:text-left 2xl:w-50vw text-color--green">
        {props.title}
      </span>
      <a
        type="button"
        className="border-2 button--regular px-2 py-0 rounded font-bold text-sm"
        href={props.url}
        download
      >
        Letöltés
      </a>
    </div>
  );
};
export default DownloadLinkComponent;
