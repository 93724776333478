import { useEffect, useState } from "react";
import { MessageType } from "../../services/message-type.enum";
import {
  loginSuccess,
  loginUser,
} from "../../services/user-service/user.service";
import MessageBarComponent from "../message-bar/message-bar.component";
import TwoFactorModalComponent from "./two-factor-modal.component";
import style from "./login-form.module.scss";
import { Link } from "react-router-dom";

type Props = {
  setMessage: any;
};

const LoginFormComponent = (props: Props): JSX.Element => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [signIn, { data }] = loginUser();
  const [messageBarType, setMessageBarType] = useState(MessageType.SUCCESS);
  const [messageBarMessage, setMessageBarMessage] = useState("");
  const [messageBarVisible, setMessageBarVisible] = useState(false);
  const [isVisibleTwoFactor, setVisibleTwoFactor] = useState(false);
  const [isUserInteractionEnabled, setUserInteractionEnabled] = useState(true);

  const hideMessageBar = () => {
    setTimeout(() => {
      setMessageBarVisible(false);
    }, 5000);
  };

  const closeModal = () => {
    setVisibleTwoFactor(false);
  };

  useEffect(() => {
    if (messageBarVisible) {
      hideMessageBar();
    }
  }, [messageBarVisible]);

  return (
    <>
      <TwoFactorModalComponent
        closeModal={closeModal}
        username={username}
        isVisible={isVisibleTwoFactor}
        setMessage={props.setMessage}
      />
      <div
        className={`xl:w-60 border-2 border-black border--green flex flex-col items-center rounded mb-12 p-8 text-color--green`}
      >
        <h1 className="text-2xl mb-4">
          <b>Belépés</b>
        </h1>
        <form
          className="flex flex-col items-center"
          onSubmit={(e) => {
            setUserInteractionEnabled(false);
            e.preventDefault();
            signIn({
              variables: { password: password, username: username },
            })
              .then((res) => {
                props.setMessage(res.data.signIn.message);
                if (res.data.signIn.type === MessageType.SUCCESS) {
                  setVisibleTwoFactor(true);
                  setUserInteractionEnabled(true);
                }
                if (res.data.signIn.type === MessageType.ERROR) {
                  setUserInteractionEnabled(true);
                }
              })
              .catch((err) => {
                if (err.message === "ThrottlerException: Too Many Requests") {
                  props.setMessage(
                    "Túl sok kérést hajtott végre! Kérem várjon egy percet, majd próbálja újra!"
                  );
                  setUserInteractionEnabled(true);
                  return;
                }
                props.setMessage(err.message);
              });
          }}
        >
          <div className="input-wrapper mb-4 flex flex-col">
            <label className="text-sm font-bold" htmlFor="username">
              Felhasználónév:
            </label>
            <input
              className="border border-black border--green rounded text-sm px-2 py-1"
              type="text"
              onChange={(event) => {
                setUsername(event.target.value);
              }}
            />
          </div>
          <div className="input-wrapper mb-4 flex flex-col">
            <label className="text-sm font-bold" htmlFor="username">
              Jelszó:
            </label>
            <input
              className="border border-black border--green rounded text-sm px-2 py-1"
              type="password"
              onChange={(event) => {
                setPassword(event.target.value);
              }}
            />
          </div>
          <Link
            to="/forgot-password"
            className="text-xs font-bold text-left w-full"
          >
            Elfelejtette a jelszavát?
          </Link>
          <button
            className={`${
              isUserInteractionEnabled ? "" : style.disabled
            } border-2 font-bold button--regular mt-4 px-4 rounded`}
            type="submit"
          >
            Belépés
          </button>
        </form>
      </div>
    </>
  );
};
export default LoginFormComponent;
