import { useMutation, useQuery } from '@apollo/client'
import { UserRole } from './user-role.enum'
import { UserStatus } from './user-status.enum'
import {
  BAN_OR_ACTIVATE_USER,
  CLOSE_USER,
  CREATE_USER,
  DELETE_USER,
  FINALIZE_ACCOUNT,
  FORGOT_PASSWORD,
  GET_ALL_USER,
  GET_USER_EXPIRATION_DATE,
  LOGIN_USER,
  RESET_PASSWORD,
  VALIDATE_TOKEN,
  VALIDATE_TWO_FACTOR_CODE,
} from './graphql.schema'

export type User = {
  id: string
  username: string
  role: UserRole
  status: UserStatus
  email: string
  name: string
}

export function ValidateUserRole() {
  const token = getToken() || ''
  return useQuery(VALIDATE_TOKEN, {
    variables: { token },
    fetchPolicy: 'network-only',
  })
}

export function getUserExpirationDate() {
  const token = getToken()
  return useQuery(GET_USER_EXPIRATION_DATE, {
    variables: { token },
    fetchPolicy: 'network-only',
  })
}

export function loginUser() {
  return useMutation(LOGIN_USER)
}

export function loginSuccess(accessToken: string) {
  localStorage.setItem('token', JSON.stringify(accessToken))
  setTimeout(() => {
    window.location.href = '/'
  }, 1500)
}

export function LogoutUser() {
  window.location.replace('/')
  localStorage.clear()
}

export function CreateUser() {
  const token = getToken()
  return useMutation(CREATE_USER, {
    refetchQueries: [{ query: VALIDATE_TOKEN, variables: { token: token } }],
  })
}
export function getToken() {
  const token = localStorage.getItem('token') ?? ''
  return token.slice(1, token.length - 1)
}

export function getAllUser() {
  const token = getToken()
  return useQuery(GET_ALL_USER, {
    variables: { token },
    fetchPolicy: 'network-only',
  })
}
export function deleteUser() {
  const token = getToken()
  return useMutation(DELETE_USER, {
    refetchQueries: [{ query: GET_ALL_USER, variables: { token: token } }],
  })
}
export function banOrActivateUser() {
  const token = getToken()
  return useMutation(BAN_OR_ACTIVATE_USER, {
    refetchQueries: [{ query: GET_ALL_USER, variables: { token: token } }],
  })
}

export function closeUser() {
  const token = getToken()
  return useMutation(CLOSE_USER, {
    refetchQueries: [{ query: GET_ALL_USER, variables: { token: token } }],
  })
}

export function finalizeAccount() {
  const token = getToken()
  return useMutation(FINALIZE_ACCOUNT, {
    refetchQueries: [{ query: GET_ALL_USER, variables: { token: token } }],
  })
}

export function validateTwoFactorToken() {
  return useMutation(VALIDATE_TWO_FACTOR_CODE)
}

export function forgotPassword() {
  return useMutation(FORGOT_PASSWORD)
}

export function resetPassword() {
  return useMutation(RESET_PASSWORD)
}
