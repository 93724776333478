import { useState } from "react";
import { Navigate } from "react-router";
import { UploadFile } from "../../services/file-service/file.service";
import { getToken } from "../../services/user-service/user.service";

type Props = {
  title: string;
  message: string;
  submitButtonName: string;
  callBack: any;
  callBackParams: any;
  isVisible: boolean;
  closeModal: any;
};
const ModalComponent = (props: Props): JSX.Element => {
  const handleCancel = () => {
    props.closeModal();
  };
  const handleSubmit = () => {
    props.callBack(props.callBackParams);
    props.closeModal();
  };

  if (!props.isVisible) {
    return <></>;
  }
  return (
    <div
      className={`${
        !props.isVisible ? "hidden" : ""
      } fixed border border-black  rounded bg-white z-10 flex flex-col items-center px-4 py-4 top-20 left-1/4 right-1/4`}
    >
      <h1 className="font-bold pb-2">{props.title}</h1>
      <h3 className="text-sm mb-4">{props.message}</h3>
      <div className="flex w-1/3 justify-between">
        <button
          className="border-2 font-bold rounded px-2 py-1 button--danger"
          onClick={handleCancel}
        >
          Mégse
        </button>
        <button
          className="border-2 font-bold rounded px-2 py-1 button--regular"
          onClick={() => handleSubmit()}
        >
          {props.submitButtonName}
        </button>
      </div>
    </div>
  );
};
export default ModalComponent;
