import { useEffect, useState } from "react";
import { GetUserFolder } from "../../services/file-service/file.service";
import FilesComponent from "../files/files.component";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";

import UploadFileModalComponent from "../upload-file-modal/upload-file-modal.component";

type Props = {
  title: string;
  folderName: string;
  setMessage: any;
  excelOnly?: boolean;
};

const FolderComponent = (props: Props): JSX.Element => {
  const [isFolderOpened, setIsFolderOpened] = useState(false);
  const [files, setFiles] = useState([]);
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);

  const [getFiles, { data }] = GetUserFolder(props.folderName);

  const handleOpen = () => {
    setIsFolderOpened(!isFolderOpened);
    getFiles();
  };

  useEffect(() => {
    if (data) {
      setFiles(data.getUserFolder);
    }
  }, [data]);

  const closeModal = (wasUpload: boolean) => {
    setIsUploadModalVisible(false);
  };

  return (
    <div className="w-80vw mt-4">
      <UploadFileModalComponent
        title={props.title}
        isVisible={isUploadModalVisible}
        folder={props.folderName}
        closeModal={closeModal}
        setMessage={props.setMessage}
        excelOnly={props.excelOnly}
      />
      <div className="flex w-full">
        <div
          className={`flex w-11/12 px-4 py-2 justify-between items-center cursor-pointer border-2 font-bold border-black button--regular rounded mb-2 mr-4 ${
            isFolderOpened ? "background--green" : ""
          }`}
          onClick={() => handleOpen()}
        >
          <span>{props.title}</span>

          {isFolderOpened ? (
            <KeyboardArrowUpRoundedIcon />
          ) : (
            <KeyboardArrowDownRoundedIcon />
          )}
        </div>
        <button
          className="border-2 font-bold border-black px-4 rounded text-xs mb-2 button--regular"
          type="button"
          onClick={() => {
            setIsUploadModalVisible(true);
          }}
        >
          Feltöltés
        </button>
      </div>
      <FilesComponent
        isOpened={isFolderOpened}
        files={files}
        folderName={props.folderName}
        setMessage={props.setMessage}
      />
    </div>
  );
};
export default FolderComponent;
