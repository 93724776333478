import React, { useEffect, useState } from 'react'

type Props = {
  message: string
}
const BackendResponseComponent = (props: Props): React.ReactElement => {
  const [isOpened, setIsOpened] = useState(false)

  useEffect(() => {
    if (props.message !== '') {
      setIsOpened(true)
    }
    const timer = setTimeout(() => {
      setIsOpened(false)
    }, 3000)
    return () => clearTimeout(timer)
  }, [props.message])

  if (!isOpened) {
    return <></>
  }

  return (
    <div className="w-10/12 relative -top-20 mx-auto flex justify-center z-50">
      <div className={` flex absolute top-20 bg-white rounded z-50`} id="box">
        <h3 className={`border-2 font-bold rounded px-4 py-2 text-color--green border--green`}>{props.message}</h3>
      </div>
    </div>
  )
}
export default BackendResponseComponent
