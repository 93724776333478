import { useEffect, useState } from "react";
import { GetUserFilesByUsername } from "../../services/file-service/file.service";
import { File } from "../../services/file-service/file.service";
import style from "./files-modal.module.scss";
import { getToken, LogoutUser } from "../../services/user-service/user.service";
const axios = require("axios").default;
import * as FileSaver from "file-saver";

type Props = {
  isVisible: boolean;
  username: string;
  closeModal: any;
  setMessage: any;
};

const FilesModalComponent = (props: Props): JSX.Element => {
  const [userFiles, setUserFiles] = useState([]);
  const [downloading, setDownloading] = useState(false);
  const { data } = GetUserFilesByUsername(props.username);

  useEffect(() => {
    if (data) {
      setUserFiles(data.getUserFilesByUsername);
    }
  }, [data]);

  useEffect(() => {
    if (downloading) {
      props.setMessage("Letöltés...");
    }
  }, [downloading]);

  const close = () => {
    props.closeModal();
  };

  const makeDownload = () => {
    setDownloading(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/files/${props.username}`, {
        responseType: "arraybuffer",
        headers: {
          Accept: "application/zip",
          Auth: getToken(),
        },
      })
      .then((response: any) => {
        const blob = new Blob([response.data], {
          type: "application/zip",
        });
        FileSaver.saveAs(blob, `${props.username}-${Date.now()}.zip`);
        setDownloading(false);
      })
      .catch((error: any) => {
        setDownloading(false);
        if (error.response.status === 403) {
          props.setMessage("Ismeretlen felhasználó! Kijelentkeztetjük!");
          setTimeout(() => {
            LogoutUser();
          }, 1600);
        }
      });
  };

  const downloadSingleFile = (folderName: string, fileName: string) => {
    setDownloading(true);
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/files/${props.username}/${folderName}/${fileName}`,
        {
          responseType: "arraybuffer",
          headers: {
            Auth: getToken(),
          },
        }
      )
      .then((response: any) => {
        const blob = new Blob([response.data], {
          type: "octet/stream",
        });
        FileSaver.saveAs(blob, fileName);
        setDownloading(false);
      })
      .catch((error: any) => {
        setDownloading(false);
        if (error.response.status === 403) {
          props.setMessage("Ismeretlen felhasználó! Kijelentkeztetjük!");
          setTimeout(() => {
            LogoutUser();
          }, 1600);
        }
      });
  };

  return (
    <div
      className={` ${
        !props.isVisible ? "hidden" : ""
      } fixed left-0 top-0 overflow-hidden ${
        style.modalBackdrop
      } flex justify-center items-center`}
    >
      <div
        className={`${
          !props.isVisible ? "hidden" : ""
        } w-full sm:w-min absolute border border-black rounded bg-white z-10 flex flex-col items-center justify-between px-4 py-4  2xl:min-h-80vh 2xl:max-h-80vh`}
      >
        <div className="w-full flex justify-between items-center gap-2 border-b-2 border-black mb-6  w-90vw lg:w-50vw">
          <h1 className="font-bold pb-2">
            {props.username} által feltöltött fájlok
          </h1>
          <button
            className={`${
              userFiles.length === 0 || downloading ? style.disabled : ""
            } border-2 font-bold text-xs rounded px-2 py-1 mb-2 button--regular`}
            type="button"
            onClick={() => {
              makeDownload();
            }}
          >
            Minden fájl letöltése zipben
          </button>
        </div>
        <div className="w-full px-4 flex">
          <span className="w-10/12 font-bold">Fájlnév</span>
        </div>
        <div
          className={`w-full min-h-50vh max-h-50vh px-4 ${style.hideScrollBar}`}
        >
          {userFiles.length === 0 ? (
            <div className="text-center mt-8">
              A felhasználó még nem töltött fel fájlt!
            </div>
          ) : (
            ""
          )}
          {userFiles.map((file: File) => (
            <div className="flex my-2 gap-2 justify-between items-center">
              <span className="w-8/12 break-words text-xs sm:text-sm">
                {file.name}
              </span>
              <button
                className={`${
                  downloading ? style.disabled : ""
                } border-2 font-bold w-3/12 text-xs rounded px-2 py-1 button--regular`}
                type="button"
                onClick={() => {
                  downloadSingleFile(file.folder, file.name);
                }}
              >
                Letöltés
              </button>
            </div>
          ))}
        </div>
        <button
          className="border-2 button--danger font-bold rounded px-2 py-1 mt-4 2xl:mt-0"
          type="button"
          onClick={close}
        >
          Bezárás
        </button>
      </div>
    </div>
  );
};
export default FilesModalComponent;
