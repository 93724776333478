import { getAllUser, User } from "../../services/user-service/user.service";
import { useEffect, useState } from "react";
import { UserStatus } from "../../services/user-service/user-status.enum";
import FilesModalComponent from "../files-modal/files-modal.component";
import DeleteButton from "./delete-button.component";
import ChangeUserStatusButton from "./change-user-status-button.component";
import CloseUserButton from "./close-user-button.component";
import DownloadButton from "./download-button.component";
import UserDataModalComponent from "./user-data-modal.component";

type Props = {
  setMessage: any;
};

const ListUsersTableComponent = (props: Props): JSX.Element => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({
    username: "",
    name: "",
    email: "",
  });
  const [isVisibleUserData, setIsVisibleUserData] = useState(false);

  const { data } = getAllUser();
  const [isVisibleUserFiles, setIsVisibleUserFiles] = useState(false);
  const [username, setUsername] = useState("");

  const showUserFiles = (username: string) => {
    setUsername(username);
    setIsVisibleUserFiles(true);
  };

  const callBackToCloseUserFilesModal = () => {
    setIsVisibleUserFiles(false);
  };

  const callbackToCloseUserDataModal = () => {
    setIsVisibleUserData(false);
  };

  useEffect(() => {
    if (data) {
      setUsers(data.getAllUser);
    }
  }, [data, username]);

  return (
    <div className="text-color--green">
      <FilesModalComponent
        isVisible={isVisibleUserFiles}
        username={username}
        closeModal={callBackToCloseUserFilesModal}
        setMessage={props.setMessage}
      />
      <UserDataModalComponent
        isVisible={isVisibleUserData}
        user={selectedUser}
        closeModal={callbackToCloseUserDataModal}
        setMessage={props.setMessage}
      />
      <div className="w-90vw sm:w-80vw 2xl:w-50vw text-sm mt-12 mb-20">
        <div className="flex flex-wrap sm:flex-nowrap justify-between sm:justify-start mb-2">
          <span className="w-3/12 min-w-200px text-left font-bold">
            Felhasználónév
          </span>
          <span className="w-3/12 min-w-100px text-left font-bold">
            Státusz
          </span>
          <div className="className='w-6/12 min-w-400px text-left'"></div>
        </div>
        <hr />
        <div className="flex flex-col gap-y-4 mt-2">
          {users.map((user: User) => (
            <div
              className="flex flex-wrap sm:flex-nowrap justify-between sm:items-center gap-y-2 border-b-2 md:border-b-0 pb-4 md:pb-0"
              key={user.id}
            >
              <span
                className="w-3/12 min-w-200px cursor-pointer"
                onClick={() => {
                  setSelectedUser({
                    username: user.username,
                    name: user.name,
                    email: user.email,
                  });
                  setIsVisibleUserData(true);
                }}
              >
                {user.username}
              </span>
              <span className="w-2/12 min-w-100px">{user.status}</span>
              <div className="w-full sm:w-7/12 justify-evenly flex flex-nowrap">
                <DeleteButton user={user} setMessage={props.setMessage} />
                <div
                  className={`${
                    user.status === UserStatus.CLOSED ? "hidden" : "flex"
                  }`}
                >
                  <ChangeUserStatusButton
                    user={user}
                    setMessage={props.setMessage}
                  />
                </div>
                <div
                  className={`${
                    user.status === UserStatus.CLOSED ? "hidden" : "flex"
                  }`}
                >
                  <CloseUserButton user={user} setMessage={props.setMessage} />
                </div>
                <button
                  type="button"
                  className="button--regular rounded border-2 px-2 font-bold"
                  onClick={() => {
                    showUserFiles(user.username);
                  }}
                >
                  Fájlok
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default ListUsersTableComponent;
