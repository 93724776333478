import { useEffect, useState } from "react";
import { RemoveFile } from "../../services/file-service/file.service";
import { File } from "../../services/file-service/file.service";
import { getToken, LogoutUser } from "../../services/user-service/user.service";
import ModalComponent from "../modal/modal.component";
const axios = require("axios").default;
import * as FileSaver from "file-saver";
import style from "./files.module.scss";

type Props = {
  folderName: string;
  files: File[];
  isOpened: boolean;
  setMessage: any;
};

const FilesComponent = (props: Props): JSX.Element => {
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [callbackParam, setCallbackParam] = useState({});
  const [removeFile] = RemoveFile(props.folderName);
  const [downloading, setDownloading] = useState(false);

  const token = getToken();

  useEffect(() => {
    if (downloading) {
      props.setMessage("Letöltés...");
    }
  }, [downloading]);

  const saveByteArray = (function () {
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style.display = "none";
    return function (data: any, name: any) {
      const blob = new Blob(data, { type: "octet/stream" }),
        url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = name;
      a.click();
      window.URL.revokeObjectURL(url);
    };
  })();

  const downloadSingleFile = (fileName: string) => {
    setDownloading(true);
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/files/${props.folderName}/${fileName}`,
        {
          responseType: "arraybuffer",
          headers: {
            Auth: getToken(),
          },
        }
      )
      .then((response: any) => {
        const blob = new Blob([response.data], {
          type: "octet/stream",
        });
        FileSaver.saveAs(blob, fileName);
        setDownloading(false);
      })
      .catch((error: any) => {
        setDownloading(false);
        if (error.response.status === 403) {
          props.setMessage("Ismeretlen felhasználó! Kijelentkeztetjük!");
          setTimeout(() => {
            LogoutUser();
          }, 1600);
        }
      });
  };

  const hideModal = () => {
    setIsVisibleModal(false);
  };

  const removeFileCallback = (fileId: string) => {
    removeFile({
      variables: {
        token: token,
        fileId: fileId,
      },
    })
      .then((res) => {
        props.setMessage(res.data.removeFile.message);
      })
      .catch((errors) => {
        console.log(errors);
        let message = errors;
        if (errors.message === "ThrottlerException: Too Many Requests") {
          props.setMessage(
            "Túl sok kérést hajtott végre! Kérem várjon egy percet, majd próbálja újra!"
          );
          return;
        }
        if (errors.graphQLErrors) {
          if (errors.graphQLErrors[0]?.extensions.response.statusCode === 403) {
            message = "Ismeretlen felhasználó! Kijelentkeztetjük!";
            setTimeout(() => {
              LogoutUser();
            }, 1600);
          }
        }
        props.setMessage(message);
      });
  };

  if (props.files.length === 0 && props.isOpened) {
    return (
      <div className="w-full flex justify-center py-4">
        <h1>Nincs megjelenítendő fájl</h1>
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col items-center text-color--green">
      <ModalComponent
        title={`Fájl törlése`}
        message={"Biztosan törölni szeretné a fájlt?"}
        submitButtonName={"Törlés"}
        callBack={removeFileCallback}
        callBackParams={callbackParam}
        isVisible={isVisibleModal}
        closeModal={hideModal}
      />
      <div className={`${props.isOpened ? "block" : "hidden"} w-full my-4`}>
        <div
          className={`${
            props.files.length === 0 ? "hidden" : "flex"
          } border-b my-2 pb-2`}
        >
          <span className="w-6/12 text-left font-bold">Fájlnév</span>
          <span className="w-3/12"></span>
          <span className="w-3/12"></span>
        </div>
        {
          <div className="">
            {props.files.map((file: any, index) => (
              <div key={file.id}>
                <div className="w-full flex flex-wrap sm:flex-nowrap py-2 items-center">
                  <div className="w-full sm:w-8/12 text-sm text-center sm:text-left mb-4 sm:mb-0">
                    {file.name}
                  </div>
                  <div className="flex min-w-200px w-full sm:w-4/12 justify-evenly">
                    <div className={`w-2/12 flex justify-end items-center`}>
                      <button
                        onClick={() => {
                          setCallbackParam(file.id);
                          setIsVisibleModal(true);
                        }}
                        className={`text-sm border-2 font-bold rounded px-2 py-1 button--danger`}
                      >
                        Törlés
                      </button>
                    </div>
                    <div className={`w-2/12 flex justify-end items-center`}>
                      <button
                        onClick={() => {
                          downloadSingleFile(file.name);
                        }}
                        className={`${
                          downloading ? style.disabledButton : ""
                        }   text-sm border-2 rounded px-2 py-1 button--regular font-bold`}
                      >
                        Letöltés
                      </button>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            ))}
          </div>
        }
      </div>
    </div>
  );
};
export default FilesComponent;
