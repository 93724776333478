import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UserRole } from "../../services/user-service/user-role.enum";
import { ValidateUserRole } from "../../services/user-service/user.service";

const HomePage = (): JSX.Element => {
  const [userRole, setUserRole] = useState(UserRole.GUEST);

  const { data } = ValidateUserRole();

  useEffect(() => {
    if (data) {
      setUserRole(data.validateToken.userRole);
    }
  }, [data]);

  if (userRole === UserRole.ADMIN) {
    document.getElementById("Felhasználók")?.click();
  }

  if (userRole === UserRole.GUEST) {
    return (
      <div className="h-full min-h-full flex flex-col m-auto justify-center items-center">
        <h1 className="text-center w-90vw 2xl:w-50vw text-color--green -mt-12">
          Üdvözöljük a <b>Szövetség a Polgári Magyarországért Alapítvány </b>
          elektronikus támogatás elszámoló rendszerében!
        </h1>
        <Link
          className="border-2 button--regular px-2 py-0 rounded text-2xl mt-12"
          to={"/login"}
        >
          Belépés
        </Link>
      </div>
    );
  }

  return (
    <div className="h-full min-h-full flex flex-col m-auto justify-center items-center">
      <h1 className="text-center w-90vw 2xl:w-50vw text-color--green -mt-4">
        Üdvözöljük a <b>Szövetség a Polgári Magyarországért Alapítvány</b>{" "}
        elektronikus támogatás elszámoló rendszerében. Amennyiben kérdése vagy
        észrevétele van az elszámolási rendszerrel vagy az elszámolással
        kapcsolatban úgy keresse alapítványunk munkatársait az{" "}
        <b>igazgatosag@szpma.hu</b> email címen.
      </h1>
      <span className="text-center w-90vw 2xl:w-50vw text-color--green mt-4">
        A támogatás elszámolásához szükséges dokumentumok a{" "}
        <Link
          className="border button--regular px-2 py-0 rounded"
          to={"/downloads"}
          onClick={() => {
            document.getElementById("Letöltés")?.click();
          }}
        >
          Letöltés
        </Link>{" "}
        menüpont alatt találhatóak.
      </span>
    </div>
  );
};
export default HomePage;
