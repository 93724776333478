import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UserRole } from "../../services/user-service/user-role.enum";
import {
  getToken,
  LogoutUser,
  ValidateUserRole,
} from "../../services/user-service/user.service";
import { navLinks } from "./nav-links";
import RemainingTime from "../remaining-time/remaining-time.component";
import style from "./header.module.scss";
import { ReactComponent as Logo } from "../../assets/images/szpma-logo.svg";
import MobileMenuComponent from "./mobile-menu.component";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";

const HeaderComponent = (): JSX.Element => {
  const [userRole, setUserRole] = useState(UserRole.GUEST);
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
  const token = getToken();
  const { data } = ValidateUserRole();

  useEffect(() => {
    if (data) {
      setUserRole(data.validateToken.userRole);
    }
  }, [data]);

  const isUser = () => {
    return userRole === UserRole.USER;
  };

  const handleOpenMobileMenu = () => {
    setIsMobileMenuVisible(true);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuVisible(false);
  };

  let navLink: any;
  const restyleLinks = () => {
    for (let i = 0; i < navLink.children.length; i++) {
      navLink.children[i].style.fontWeight = "normal";
    }
  };
  return (
    <div className={`${style.background} w-full text-white`}>
      <div
        className={`w-full sm:w-5/6 flex items-center justify-between mx-auto`}
      >
        <a href="/" className={`${style.logoMobile} sm:${style.logo} sm:pl-2`}>
          <Logo className={`${style.logoSvg}`} />
        </a>
        <div
          className="hidden lg:flex gap-4 h-full"
          id="nav-links"
          ref={(node: any) => {
            navLink = node;
          }}
        >
          {navLinks
            .filter((link) => {
              return link.requiredRole.includes(userRole);
            })
            .map((menu) => (
              <Link
                className={`${
                  window.location.pathname === menu.path ? "font-bold" : ""
                } capitalize text-base ${style.menuItem}`}
                id={`${menu.name}`}
                key={`${menu.path}`}
                to={`${menu.path}`}
                onClick={(node: any) => {
                  restyleLinks();
                  node
                    ? node.target
                      ? (node.target.style.fontWeight = "bold")
                      : console.log()
                    : console.log();
                }}
              >
                {menu.name.toUpperCase()}
              </Link>
            ))}
          {userRole !== UserRole.GUEST ? (
            <button onClick={LogoutUser}>{"Kilépés".toUpperCase()}</button>
          ) : (
            ""
          )}
        </div>
        <div
          className="flex lg:hidden cursor-pointer mr-4 border-2 px-2 py-1 rounded"
          onClick={() => {
            handleOpenMobileMenu();
          }}
        >
          <MenuRoundedIcon />
        </div>
        <MobileMenuComponent
          links={navLinks}
          isMobileMenuVisible={isMobileMenuVisible}
          closeMobileMenu={closeMobileMenu}
        />
      </div>
      <div className="w-full bg-white flex items-center justify-center mx-auto">
        {isUser() ? <RemainingTime /> : <></>}
      </div>
    </div>
  );
};

export default HeaderComponent;
