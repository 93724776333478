import { UserRole } from "../../services/user-service/user-role.enum";

export interface RouteDescriptor {
  name: string;
  path: string;
  requiredRole: UserRole[];
}

export const navLinks: RouteDescriptor[] = [
  /* {
    name: 'Főoldal',
    path: '/',
    requiredRole: [UserRole.GUEST, UserRole.USER, UserRole.ADMIN],
  },
  {
    name: 'Belépés',
    path: '/login',
    requiredRole: [UserRole.GUEST],
  }, */
  {
    name: "Felhasználó létrehozása",
    path: "/create-user",
    requiredRole: [UserRole.ADMIN],
  },
  {
    name: "Felhasználók",
    path: "/list-users",
    requiredRole: [UserRole.ADMIN],
  },
  {
    name: "Feltöltés",
    path: "/user-files",
    requiredRole: [UserRole.USER],
  },
  {
    name: "Letöltés",
    path: "/downloads",
    requiredRole: [UserRole.USER],
  },
];
