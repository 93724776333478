import DownloadFolderComponent from "../../components/download/download-folder.component";
import DownloadLinkComponent from "../../components/download/download-link.component";

const DownloadsPage = (): JSX.Element => {
  const usefulDocuments = [
    {
      title: "Útmutató a támogatás beszámolójának elkészítéséhez:",
      url: "/files/utmutato_a_tamogatas_elszamolasahoz.pdf",
    },
    {
      title: "Számlaösszesítő táblázat:",
      url: "/files/szamlaosszesito_tablazat.xlsx",
    },
    {
      title: "Polgári Magyarországért Alapítvány arculati eleme:",
      url: "/files/PMA_logo_tamogatottaknak.jpg",
    },
  ];

  const sampleDocuments = [
    {
      title: "Készpénzes számla kiadási pénztárbizonylat minta:",
      url: "/files/keszpenzes_szamla_kiadasi_pénztárbizonylat-minta.png",
    },
    {
      title: "Záradékolt számla minta:",
      url: "/files/zaradekolt_szamla-minta.png",
    },
    {
      title: "Bankszámlakivonat minta:",
      url: "/files/bankszamlakivonat-minta.jpg",
    },
  ];

  const downloadableDocuments = [
    {
      title: "Útmutató a támogatás beszámolójának elkészítéséhez:",
      url: "/files/Utmutato_a_tamogatas_beszamolojanak_elkeszitesehez.pdf",
    },
    {
      title: "Számlaösszesítő táblázat:",
      url: "/files/Szamlaosszesito_tablazat_2024.xlsx",
    },
    {
      title: "Polgári Magyarországért Alapítvány logója:",
      url: "/files/PMA_logo_tamogatottaknak.jpg",
    },
  ];

  return (
    <div className="h-full min-h-full flex flex-col m-auto justify-center items-center gap-y-4 mt-8 mb-16">
      <DownloadFolderComponent
        title="Letölthető dokumentumok"
        documents={downloadableDocuments}
      />
    </div>
  );
};
export default DownloadsPage;
